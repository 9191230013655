import React, { useEffect, useState } from "react";

function RedirectExample() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace("https://spacestation.spaceskellies.io/");
    }, 300);

    return () => clearTimeout(timeout);
  }, []);

  return <>Teleporting to the Space Station</>;
}

export default RedirectExample;
