import React from 'react';
import Button from '../components/button';
import img1 from '../assets/images/common/404.png';

function Page404(props) {
    return (
        <section className="page-title p404">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                            <div className="breadcrumbs center" data-aos="fade-up" data-aos-duration="800">
                                <h1>404</h1>
                                <h2>{/*Page Not Found*/}We are lost</h2>
                                <p>{/*We can’t find the page that you’re looking for.*/}There’s a lot of space out there to get lost in.</p>
                                <Button title='Back To Homepage' path='/' /><br />
                                <img alt='Space Skellies' src={img1}></img>
                            </div>            
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Page404;