import img1 from '../images/common/img1.jpg'
import img2 from '../images/common/img2.jpg'
import img3 from '../images/common/img3.jpg'
import img4 from '../images/common/img4.jpg'
import img5 from '../images/common/img5.jpg'
import img6 from '../images/common/img6.jpg'
import img7 from '../images/common/img7.jpg'
import img9 from '../images/common/img9.jpg'
import img10 from '../images/common/img10.jpg'
import img11 from '../images/common/img11.jpg'
import img12 from '../images/common/img12.jpg'


const dataProject = [
    {
        id: 1,
        //title: 'Space Skellie #01',
        img: img1,
    },
    {
        id: 2,
        //title: 'Space Skellie  #02',
        img: img2,
    },
    {
        id: 3,
        //title: 'Space Skellie  #03',
        img: img3,
    },
    {
        id: 4,
        //title: 'Space Skellie  #04',
        img: img4,
    },
    {
        id: 5,
        //title: 'Space Skellie  #05',
        img: img5,
    },
    {
        id: 6,
        //title: 'Space Skellie  #06',
        img: img6,
    },
    {
        id: 7,
        //title: 'Space Skellie  #07',
        img: img7,
    },
    {
        id: 8,
        //title: 'Space Skellie  #08',
        img: img2,
    },

    {
        id: 9,
        //title: 'Space Skellie  #09',
        img: img9,
    },
    {
        id: 10,
        //title: 'Space Skellie  #10',
        img: img10,
    },
    {
        id: 11,
        //title: 'Space Skellie  #11',
        img: img11,
    },
    {
        id: 12,
        //title: 'Space Skellie  #12',
        img: img12,
    },
]

export default dataProject;