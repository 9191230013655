
import Stargaze from '../images/logo/stargaze.png'
import DreamInk from '../images/logo/dreamink.png'
const dataPartner = [
    {
        img: Stargaze,
        link: "https://www.stargaze.zone/",
    },
    {
        img: DreamInk,
        link: "https://dreamink.com.ua/",
    },
    {
        img: Stargaze,
        link: "https://www.stargaze.zone/",
    },
    {
        img: DreamInk,
        link: "https://dreamink.com.ua/",
    },
    {
        img: Stargaze,
        link: "https://www.stargaze.zone/",
    },
    {
        img: DreamInk,
        link: "https://dreamink.com.ua/",
    },
    {
        img: Stargaze,
        link: "https://www.stargaze.zone/",
    },
    {
        img: DreamInk,
        link: "https://dreamink.com.ua/",
    },
    {
        img: Stargaze,
        link: "https://www.stargaze.zone/",
    },
    {
        img: DreamInk,
        link: "https://dreamink.com.ua/",
    },
]

export default dataPartner;