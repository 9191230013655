import EJ_pfp from '../images/team/ej-pfp.jpg';
import Mahyar7r_pfp from '../images/team/mahyar7r-pfp.jpg';
import RamadanSteve_pfp from '../images/team/ramadansteve-pfp.jpg';
import Slobbers_pfp from '../images/team/slobbers-pfp.jpg';
import GluckMedia_pfp from '../images/team/gluckmedia-pfp.jpg';
import Bbandz_pfp from '../images/team/bbandz-pfp.jpg';
import Octane_pfp from '../images/team/octane-pfp.jpg';
import JGnft_pfp from '../images/team/jgnft-pfp.jpg';
import BigBooms_pfp from '../images/team/bigbooms-pfp.jpg';
import bWy_pfp from '../images/team/bwy-pfp.jpg';

const dataTeam = [
    {
        id: 1,
        img: EJ_pfp,
        name: 'EJ',
        position: 'Founder',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'http://twitter.com/spaceskellies'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: 'https://t.me/EJ_SpaceSkellies'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'https://discord.com/users/456875327348539414'
            },
        ]
    },
    {
        id: 2,
        img: GluckMedia_pfp,
        name: 'GluckMedia',
        position: 'Artist',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/gluckmedia'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'http://discordapp.com/users/758455400026341458'
            },
        ]
    },
    {
        id: 3,
        img: RamadanSteve_pfp,
        name: 'RamadanSteve',
        position: 'Advisor',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/RamadanSteve840'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'http://discordapp.com/users/744036850267717662'
            },
        ]
    },
    {
        id: 4,
        img: JGnft_pfp,
        name: 'JGnft',
        position: 'Advisor',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/JGnft_is_me'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'http://discordapp.com/users/783309605605998603'
            },
        ]
    },
    {
        id: 5,
        img: Slobbers_pfp,
        name: 'Slobbers',
        position: 'Moderator',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/slobbers236'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'https://discord.com/users/567332079671705620'
            },
        ]
    },
    {
        id: 6,
        img: Mahyar7r_pfp,
        name: 'Mahyar7r',
        position: 'Server Manager',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/mahyar7r'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'http://discordapp.com/users/642422590262214662'
            },
        ]
    },
    {
        id: 7,
        img: Octane_pfp,
        name: 'Octane',
        position: 'Graphic Designer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'http://twitter.com/octaneluna'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: 'https://t.me/octane_luna'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'http://discordapp.com/users/142396982244278272'
            },
        ]
    },
    {
        id: 8,
        img: BigBooms_pfp,
        name: 'BigBooms',
        position: 'Collaborations',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/BIGBOOMS6394'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'https://discord.com/users/716641683357302814'
            },
        ]
    },
    {
        id: 9,
        img: Bbandz_pfp,
        name: 'Bbandz',
        position: 'Writer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/BeezIrish'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'https://discord.com/users/820669049074614283'
            },
        ]
    },
    {
        id: 10,
        img: bWy_pfp,
        name: 'bWy',
        position: 'Moderator',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/bwytheartist'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'https://discord.com/users/185106404820910081'
            },
        ]
    },
]

export default dataTeam;