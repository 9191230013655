const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 3,
        name: 'SpaceStation',
        links: '/SpaceStation',
    },
    {
        id: 4,
        name: 'Road Map',
        links: '/Roadmap',
    },

    /*
    {
        id: 5,
        name: 'Blog',
        links: '/blog',
        
        namesub: [
            {
                id: 1,
                sub: 'Our Blog',
                links: '/blog'
            },
            {
                id: 2,
                sub: 'Blog Details',
                links: '/blog-details'
            },
        ],
        
    },
    
    {
        id: 6,
        name: 'Pvp game (soon)',
        links: '#'
    },*/
    {
        id: 7,
        name: 'Whitepaper',
        links: '/WhitePaper'
    },
    {
        id: 2,
        name: 'About',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'About Us',
                links: '/about'
            },
            {
                id: 5,
                sub: 'Team',
                links: '/team'
            },
            {
                id: 6,
                sub: 'FAQ',
                links: '/faq'
            },

        ]
    },
]

export default menus;