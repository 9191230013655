import React from 'react';

import videoBg from '../assets/videoBg2.mp4'
import '../scss/components/section.scss';
import '../scss/components/box.scss';
import Slider from '../components/slider';
import dataSlider from '../assets/data/data-slider';
import Gallery1 from '../features/project';
import Gallery2 from '../features/project/s2';
import imageSet1 from '../assets/data/image-set1';
import imageSet2 from '../assets/data/image-set2';
import Partner from '../features/partner';
import dataPartner from '../assets/data/data-partner';
import FAQ from '../features/faq'; //don't delete


function Home(props) {

    return (
        <div className='home-1'>


            <Slider data={dataSlider} />
            <br></br>
            <Gallery1 data={imageSet1} />
            <Gallery2 data={imageSet2} />

        </div>

    );
}

export default Home;