//import bgImg1 from '../images/background/bg-1.jpg';
//import bgImg2 from '../images/background/bg-2.jpg';
import img1 from '../images/common/dopamine.png';
import img2 from '../images/logo/op_planet.png';

const dataSlider = [
    {
        id: 1,
        title: 'Skelly Universe!',
        desc: 'Exploring the unknowns of the Galaxy... ',
        //bgImg: bgImg1,
        img: img1
    },

]

export default dataSlider;