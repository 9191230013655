import React from 'react';
import PageTitle from '../components/pagetitle';
import { Link } from 'react-router-dom'

import img1 from '../assets/images/common/spaceboard.jpg';

function Contact(props) {
    return (
        <div>
            <PageTitle title='Space Board' />

            <section className="tf-section tf-contact">
                <div className="container">
                    <div className="row">
                        <p className="justify">
                        <img src={img1}></img>
                        <br />
                        </p>
                        <p>
                            <h3>PRIZE LIST</h3>
                            1 - A1 : 300 Stars + (1) Space Skelly #734 <br />
                            5 - H6 : 300 Stars<br />
                            7 - E10 : 300 Stars<br />
                            8 - B1 : 300 Stars + (1) TSAS Ape #2065<br />
                            10 - D2 : 250 Stars<br />
                            12 - D3 : 300 Stars<br />
                            21 - H10 : 200 Stars + (1) Space Skelly NFT #3244<br />
                            32 - I7 : 300 Stars<br />
                            34 - B9 : 300 Stars<br />
                            38 - F8 : 350 Stars + (1) Space Skelly NFT #474<br />
                            45 - F9 : 650 Stars<br />
                            64 - C5 : 250 Stars + (1) Space Buddy<br />
                            65 - E1 : 250 Stars<br />
                            69 - D5 : 500 Stars + (1) Space Skelly #913<br />
                            71 - I1 : 300 Stars<br />
                            79 - I4 : 300 Stars<br />
                            87 - I6 : 250 Stars + (1) Space Skelly NFT #3287<br />
                            89 - F2 : 300 Stars <br />
                            96 - D8 : 500 Stars<br />
                            100 - F4 : 250 Stars<br />
                            109 - F7 : 300 Stars<br />
                            117 - G4 : 250 Stars + (1) Space Skelly #948<br />
                            129 - G3 : 400 Stars<br />
                            144 - D10 : 300 Stars<br />
                            167 - G10 : 300 Stars + (1) Sunnyside Up #127<br />
                            169 - A3 : 325 Stars<br />
                            213 - H2 : 300 Star + (1) Space Skelly NFT #3302<br />
                            225 - A8 : 625 Stars<br />
                            305 - A1 : 300 Stars  <br />
                            313 - J2 : 250 Stars<br />
                            320 - F1 : 350 Stars + (1) Space Skelly #98<br />
                            353 - G5 : 250 Stars <br />
                            369 - J4 : 300 Stars<br />
                            411 - F5 : 250 Stars<br />
                            412 - I5 : 300 Stars + (1) Space Skelly #3166<br />
                            420 - A5 : 350 Stars<br />
                            444 - C4 : 250 Stars<br />
                            458 - I9 : 600 Stars + (1) TSAS Buddy #0161<br />
                            469 - J9 : 300 Stars <br />
                            506 - J3 : 300 Stars<br />
                            525 - F3 : 350 Stars + (1) Space Skelly #1005<br />
                            576 - G9 : 300 Stars<br />
                            619 - H3 : 300 Stars<br />
                            666 - E4 : 250 Stars + (1) Space Skelly #3026<br />
                            678 - H7 : 300 Stars + (1) The Creatures #582<br />
                            692 - J7 : 250 Stars <br />
                            704 - D4 : 350 Stars<br />
                            707 - A4 : 300 Stars + (1) Space Skelly #2919<br />
                            714 - E9 : 300 Stars<br />
                            717 - J1 : 400 Stars<br />
                            762 - E3 : 300 Stars + (1)TSAS Buddy #0108<br />
                            777 - B8 : 250 Stars<br />
                            786 - A2 : 350 Stars<br />
                            800 - I10 : 300 Stars + (1) Space Skelly #1202<br />
                            850 - F10 : 300 Stars + (1) Rekt Bull Barn<br />
                            869 - E2 : 250 Stars<br />
                            888 - B6 : 300 Stars<br />
                            938 - D9 : 350 Stars<br />
                            999 - C9 : 300 Stars + (1) The Others #10<br />
                            1000 - I8 : 400 Stars<br />
                            1007 - A7 : 300 Stars<br />
                            1020 - F6 : 250 Stars<br />
                            1111 - J6 : 300 Stars + (1) Space Skelly #1556<br />
                            1199 - G7 : 300 Stars <br />
                            1212 - B10 : 350 Stars<br />
                            1222 - H8 : 400 Stars + (1) The Creatures #1284<br />
                            1234 - A6 : 250 Stars <br />
                            1269 - E6 : 300 Stars<br />
                            1512 - B3 : 1000 Stars<br />
                            1549 - H1 : 300 Stars<br />
                            1717 - C3 : 300 Stars<br />
                            1728 - G5 : 250 Stars + (1) Space Skelly #2621<br />
                            1800 - B5 : 300 Stars <br />
                            1814 - E7 : 400 Stars<br />
                            1906 - J5 : 300 Stars<br />
                            1919 - C7 : 250 Stars<br />
                            1964 - H9 : 300 Stars + (1) Space Skelly 1782<br />
                            1972 - J10 : 350 Stars<br />
                            1983 - G8 : 1000 Stars<br />
                            1985 - B2 : 400 Stars + (1) TSAS Buddies #0114<br />
                            2001 - G2 : 300 Stars<br />
                            2008 - B7 : 700 Stars<br />
                            2020 - E5 : 350 Stars<br />
                            2222 - C10 : 350 Stars<br />
                            2326 - C6 : 250 Stars + (1) Space Skelly #2341<br />
                            2455 - C2 : 300 Stars<br />
                            2469 - A10 : 400 Stars<br />
                            2673 - H4 : 300 Stars<br />
                            2674 - I3 : 250 Stars + (1) TSAS Buddy #1129<br />
                            2727 - C1 : 350 Stars<br />
                            2800 - D7 : 300 Stars <br />
                            2834 - C8 : 300 Stars<br />
                            2871 - J8 : 250 Stars + (1) Space Skelly #2095<br />
                            2978 - B4 : 400 Stars<br />
                            3076 - D6 : 300 Stars<br />
                            3333 - G1 : 300 Stars <br />
                            3388 - I2 : 1000 Stars<br />
                            3391 - E8 : 350 Stars + (1) Space Skelly #1854<br />


                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;