import Home from "./Home";
import AboutUs from "./AboutUs";
//import ComingSoon from "./ComingSoon";
//import Community from "./Community";
import FAQ from "./FAQ";
import Page404 from "./404";
import RoadMap from "./RoadMap";
//import NftItem from "./NftItem";
import NftItemDetails from "./NftItemDetails";
//import Blog from "./Blog";
//import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import Team from "./Team";
import EJ from "../features/team/team-members/EJ";
import GluckMedia from "../features/team/team-members/GluckMedia";
import RamadanSteve from "../features/team/team-members/RamadanSteve";
import JGnft from "../features/team/team-members/JGnft";
import Slobbers from "../features/team/team-members/Slobbers";
import Mahyar7r from "../features/team/team-members/Mahyar7r";
import Octane from "../features/team/team-members/Octane";
import BigBooms from "../features/team/team-members/BigBooms";
import Bbandz from "../features/team/team-members/Bbandz";
import Bwy from "../features/team/team-members/bWy";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Cookies from "./Cookies";
import Whitepaper from "./Whitepaper";
import SpaceBoard from "./SpaceBoard";
import SpaceStation from "./SpaceStation";


const routes = [
  { path: '/', component: <Home /> },
  { path: '/about', component: <AboutUs /> },
  //{ path: '/coming-soon', component: <ComingSoon />},
  //{ path: '/community', component: <Community />},
  { path: '/faq', component: <FAQ /> },
  { path: '/404', component: <Page404 /> },
  { path: '/roadmap', component: <RoadMap /> },
  //{ path: '/nft-item', component: <NftItem />},
  { path: '/nft-item-details', component: <NftItemDetails /> },
  //{ path: '/blog', component: <Blog />},
  { path: '/WhitePaper', component: <Whitepaper /> },
  //{ path: '/blog-details', component: <BlogDetails />},
  { path: '/contact', component: <Contact /> },
  { path: '/team', component: <Team /> },
  { path: '/terms', component: <Terms /> },
  { path: '/privacy', component: <Privacy /> },
  { path: '/cookies', component: <Cookies /> },
  { path: '/EJ', component: <EJ /> },
  { path: '/GluckMedia', component: <GluckMedia /> },
  { path: '/RamadanSteve', component: <RamadanSteve /> },
  { path: '/JGnft', component: <JGnft /> },
  { path: '/Slobbers', component: <Slobbers /> },
  { path: '/Mahyar7r', component: <Mahyar7r /> },
  { path: '/Octane', component: <Octane /> },
  { path: '/Bbandz', component: <Bbandz /> },
  { path: '/BigBooms', component: <BigBooms /> },
  { path: '/bWy', component: <Bwy /> },
  { path: '/SpaceBoard', component: <SpaceBoard /> },
  { path: '/SpaceStation', component: <SpaceStation /> },
]

export default routes;