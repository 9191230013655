import React from 'react';
import PageTitle from '../components/pagetitle';
import { Link } from 'react-router-dom';
import Team from '../features/team';
import dataTeam from '../assets/data/data-team';
import Partner from '../features/partner';
import dataPartner from '../assets/data/data-partner';


function TeamDetail(props) {
    return (
        <div>
            <PageTitle title='Our Team' />

            <Team data={dataTeam} />

            <Partner data={dataPartner} />
        </div>
    );
}

export default TeamDetail;