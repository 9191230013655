const dataFaq = [
    {
        id: 1,
        title: 'What are the NFTs?',
        content: 'A non-fungible token (NFT) is a record on a blockchain which is associated with a particular digital or physical asset. The ownership of an NFT is recorded in the blockchain, and can be transferred by the owner, allowing NFTs to be sold and traded.',
        show: 'show'
    },
    {
        id: 2,
        title: 'What are SpaceFuels?',
        content: 'Spacefuels are known as our in-game discord currency that allows you to purchase your whitelist, NFTs from the treasury, WL from other projects, merchandise from our store.',
    },
    {
        id: 3,
        title: 'What are elixirs?',
        content: 'Elixirs are rewarded through soft staking to our holders. Once you mint a space skellie or purchase from secondary, a timer will begin.  After every 30 days you’re rewarded a vial for simply holding the NFT.  Once you have harvested 4 elixirs you will then consume the vials and the conditioning process begins!',
    },
    {
        id: 4,
        title: 'What is a conditional NFT?',
        content: 'Conditional NFTs, also known as dynamic NFTs, are NFTs that change based on time duration. Once you consume your vials, the conditioning process begins, and your NFT will begin to change.',
    },
]

export default dataFaq;