import React from 'react';
import PageTitle from '../components/pagetitle';
import { Link } from 'react-router-dom'

function Contact(props) {
    return (
        <div>
            <PageTitle title='WhitePaper' />

            <section className="tf-section tf-contact">
                <div className="container">
                    <div className="row">
                        <p className="center">
                        <h6 className="h8">About the collection </h6>
                        <br />
                        Space Skellies is a collection of 3500NFTs on Stargaze!  Our project originated on Terra but thankfully we didn’t mint before the death spiral! After the death spiral we took a step back and really looked at how we could better the ecosystem as a whole! Every project seemed to be only doing PFPs, which works for some, but we felt something more could be done. 
                        <br />
                        We brought on an incredible team of 10 fully doxed members and decided that Polygon was the best chain for us to build on. However after multiple delays for months and months, we decided to launch on Stargaze! One of the best decisions we ever made.
                        <br /><br /><br /><br />
                        
                        <h6 className='h8'>Whitelist / Cost / Spacefuels</h6>
                        
                        Space Skellies has 100% minted out and one one of the first whitelist only mints on SG. This was to protect the community from bots. 
                        <br /><br />
                        Crazy as it seems, We launched on the day CZ released info about FTX.  We had just started mint. Sold 40% in under one hour. Then the info came out. 
                        <br /><br />
                        After everything settled down we still managed to completely mint out becoming one of the largest market cap projects on Stargaze!
                        <br /><br />
                        <li className='li_privacy'>Space Pioneer minted for $30</li>
                        <li className='li_privacy'>Public minted for $45</li>
                        <br /><br /><br /><br />


                        <h6 className='h8'>Spacefuels Discord Currency</h6>
                        Spacefuels are known as our in-game discord currency that allowed you to purchase your whitelist!  You could even get all the way up to Space Pioneer OG WL.
                        <br /><br />
                        Post mint, Spacefuels can now be used to purchase the following:
                        <br />
                        <li className='li_privacy'>NFTs from other projects from our treasury</li>
                        <li className='li_privacy'>WL from other projects</li>
                        <li className='li_privacy'>Merch from our store</li>
                        <br />
                        You can win spacefuels by staying active within discord through Rumbles, Poker, Gartic, etc…
                        <br /><br /><br /><br />





                        <h6 className='h8'>Roadmap</h6>
                        <br /><br />
                        <h6 className='h8'>Phase 1</h6>
                        <li className='li_privacy'>✅ Continue designing Space Skellies with input from the community and partners </li>
                        <li className='li_privacy'>✅ Bring on a full core team to help build Space Skellies</li>
                        <li className='li_privacy'>✅ Grow our socials and community organically giveaways, game nights, etc...</li>
                        <li className='li_privacy'>✅ Design and release Space Skellies Website</li>
                        <li className='li_privacy'>✅ Launch Space Skellies Collection (mint)</li>

                        <h6 className='h8'>Phase 2</h6>                        
                        <li className='li_privacy'>✅ Invest 85% of the net profit into the Ecosystem using medium-high risk strategies</li>
                        <li className='li_privacy'>✅ Purchase NFT(s) from exclusive projects to provide more access to our holders</li>
                        <li className='li_privacy'>✅ Host high-stakes events for the community with large prize pools</li>
                        <li className='li_privacy'>✅ Release merch collection to the public</li>

                        <h6 className='h8'>Phase 2</h6>                        
                        <li className='li_privacy'>✅ Solidify Space Skellies as 100% self-sustainable collection that provides ROI</li>
                        <li className='li_privacy'>✅ Introduce Staking</li>
                        <li className='li_privacy'>✅ Introduce P2E</li>
                        <br /><br /><br /><br />




                        
                        <h6 className='h8'>Elixirs</h6>
                        Elixirs are rewarded through soft staking to our holders. You do not lock up your NFT. You retain ownership and can sell anytime that you wish to. Once you mint a space skellie or purchase from secondary, a timer will begin.  For every (2) Skellies that you hold for every 30 days, you’re rewarded with (1) Elixir.
                        <br /><br />
                        Elixirs are what begin the process for your Evil Skelly. Once you have received 4 Elixirs, you will burn them for (1) Spaceship!
                        <br /><br />
                        A spaceship can then be burnt for an Evil Skelly if desired.
                        <br /><br /><br /><br />


                        <h6 className='h8'>Evil Skelly Process</h6>
                        
                        After you burn (4) Elixirs you will receive (1) Spaceship.
                        <br /><br />
                        This Spaceship is the gateway to the Evil Skellies!  
                        <br /><br />
                        You can take your chances and burn your spaceship for a random Evil Skelly. Second option is to keep it and not burn. Similar to an unopened pack of cards.

                        <br /><br /><br /><br />
                        
                        

                        
                        <h6 className='h8'>Benefits of holding a Space Skelly</h6>

                        <li className='li_privacy'>Endless value as listed above</li>
                        <li className='li_privacy'>Transparent, active, and fully doxed team</li>
                        <li className='li_privacy'>First access to future collections</li>
                        <li className='li_privacy'>Elixirs</li>
                        <li className='li_privacy'>Spaceships</li>
                        <li className='li_privacy'>Evil Skellies</li>
                        <li className='li_privacy'>Validator Rewards</li>
                        <li className='li_privacy'>Community collections and airdrops</li>
                        <li className='li_privacy'>P2E designed by Dream Ink Studio</li>
                        <br /><br /><br /><br />




                        <h5 className='h8'>THANK YOU!!</h5>
                        We couldn’t be where we are without our incredible team and community!
                        
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;