import React from 'react';
import PageTitle from '../components/pagetitle';


function Contact(props) {
    return (
        <div>
            <PageTitle title='Cookies' />

            <section className="tf-section tf-contact">
                <div className="container">
                    <div className="row">
                        <p className="justify">
                        Information about our use of cookies on the Space Skellies website.
                        <br />
                        Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.
                        <br /><br /><br />
                        A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
                        <br />
                        We use the following cookies:
                        <ul>
                            <li className='li_privacy'>Strictly necessary cookies. These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log in to secure areas of our website, use a shopping cart or make use of e-billing services.</li>

                            <li className='li_privacy'>Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</li>

                            <li className='li_privacy'>Functionality cookies. These are used to recognize you when you return to our website. This enables us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).</li>

                            <li className='li_privacy'>Targeting cookies. These cookies record your visit to our website, the pages you have visited, and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</li>
                        </ul>
                        <br /><br />
                         
                        
                        You can find more information about the individual cookies we use and the purposes for which we use them in the list below:
                        <br />
                        
                        <ul><br />
                            Name: Session ID
                            <li className='li_privacy'>This cookie is used for user authentication. It’s required to identify the user. This will prevent the user from entering his username and his password in every request.</li>
                            <br />
                            Name: csrftoken
                            <li className='li_privacy'>The purpose of this cookie is a security purpose. This is used to prevent the Cross-site request forgery attack</li>
                            <br />
                            Name: refcode
                            <li className='li_privacy'>This cookie is used for the affiliate program to track the users that subscribed using this program.</li>
                        </ul>
                         
                        <br /><br />
                        Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.
                        <br />
                        You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.
                        <br />
                        If you have any question on your cookies please contact us.

                        </p>
                    
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;