const dataAbout = [
    {
        id: 1,
        title: 'We bring you Space Skellies!',
        desc: 'We’re a collection of 3500 Skellies protecting the galaxy one planet at a time! All of our traits and designs are hand drawn by our exclusive artist!  However, we’re more than just a PFP collection, you’ll be able to play as Skellies in our P2E and PVP as we create them!',
        /* class: 'active' */
    },
    {
        id: 2,
        title: 'Benefits',
        desc: 'More benefits for our holders include staking to earn, secondary collections, offchain P2E and PVP. You will even be able to purchase in-game-items with out token, rent, trade, and sell. Space Skellies will help fight off the waves of disaster as they travel the galaxy looking for the “Apocalyptic” weapon that has been rumored could destroy Mother Earth.',
    },
    {
        id: 3,
        title: 'Blue Chip',
        desc: 'Space Skellies aims to be the blue chip on Stargaze blockchain! Space Skellies is also voted the most anticipated collection with over 25% of the community voting for us!',
    },
]

export default dataAbout;