import img13 from '../images/common/img13.jpg'
import img14 from '../images/common/img14.jpg'
import img15 from '../images/common/img15.jpg'
import img16 from '../images/common/img16.jpg'
import img17 from '../images/common/img17.jpg'
import img18 from '../images/common/img18.jpg'
import img19 from '../images/common/img19.jpg'
import img20 from '../images/common/img20.jpg'
import img21 from '../images/common/img21.jpg'
import img22 from '../images/common/img22.jpg'
import img23 from '../images/common/img23.jpg'
import img24 from '../images/common/img24.jpg'


const dataProject = [

    {
        id: 13,
        //title: 'Space Skellie  #18',
        img: img13,
    },
    {
        id: 14,
        //title: 'Space Skellie  #18',
        img: img14,
    },
    {
        id: 15,
        //title: 'Space Skellie  #18',
        img: img15,
    },
    {
        id: 16,
        //title: 'Space Skellie  #18',
        img: img16,
    },
    {
        id: 17,
        //title: 'Space Skellie  #18',
        img: img17,
    },
    {
        id: 18,
        //title: 'Space Skellie  #18',
        img: img18,
    },
    {
        id: 19,
        //title: 'Space Skellie  #19',
        img: img19,
    },
    {
        id: 20,
        //title: 'Space Skellie  #20',
        img: img20,
    },
    {
        id: 21,
        //title: 'Space Skellie  #21',
        img: img21,
    },
    {
        id: 22,
        //title: 'Space Skellie  #22',
        img: img22,
    },
    {
        id: 23,
        //title: 'Space Skellie  #23',
        img: img23,
    },
    {
        id: 24,
        //title: 'Space Skellie  #24',
        img: img24,
    },
]

export default dataProject;