const dataRoadmap = [
    {
        id: 1,
        time: 'Q1 2022',
        list: [
            {
                text: '✅ Continue designing Space Skellies with input from the community and partners'
            },
            {
                text: '✅ Bring on a full core team to help build Space Skellies'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Q2 2022',
        list: [
            {
                text: '✅ Grow our socials and community organically giveaways, game nights, etc'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Q3 2022',
        list: [      
            {
                text: '✅ Design and release Space Skellies Website'
            }, 
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 4,
        time: 'Q4 2022',
        list: [
            {
                text: 'Launch Space Skellies Collection (mint)'
            },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: 'Q1 2023',
        list: [
            {
                text: 'Invest 85% of the net profit into the Ecosystem using medium-high risk strategies'
            },
            {
                text: 'Releasing more info about our Mobile Game'
            },
            
        ],
        positon: 'left'
    },
    {
        id: 6,
        time: 'Q2 2023',
        list: [
            {
                text: 'Alpha testing of Space Skellies Mobile Game'
            },
        ],
        positon: 'right'
    },
    {
        id: 7,
        time: 'Q3 2023',
        list: [
            {
                text: 'Start to work on a FPS game for pc'
            },
        ],
        positon: 'left'
    },
    {
        id: 8,
        time: 'Q4 2023',
        list: [
            {
                text: 'To be disclosed'
            },
        ],
        positon: 'right'
    },
]

export default dataRoadmap;